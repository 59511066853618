import React from 'react';
import './App.scss';


function App() {
  return (
    <div className="App">    
      <div className="background">safasfsd</div> 
    </div>
  );
}

export default App;
